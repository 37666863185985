import { PropsWithChildren } from "react";
import styles from "./Popover.module.css";
import { useTranslation } from "react-i18next";

interface PopoverProps {
  open: boolean;
  onClose?: () => void;
}

export default function Popover({
  onClose,
  open,
  children,
}: PropsWithChildren<PopoverProps>) {
  const { t } = useTranslation();
  return (
    <div className={`${styles.popover} ${open ? styles.open : styles.closed}`}>
      {children}
      <div className={styles["popover-button-container"]}>
        <button onClick={(_) => onClose?.()}>{t("common.close")}</button>
      </div>
    </div>
  );
}
